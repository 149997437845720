<template>
  <div class="fixed-aside-box">
    <div class="top-imgs"></div>
    <div class="aside-item wechat">
      <div class="aside-imgs"></div>
      <div class="aside-txt">公众号</div>
      <div class="position-sub">
        <div class="position-img"></div>
      </div>
    </div>
    <div class="aside-item phone">
      <div class="aside-imgs"></div>
      <div class="aside-txt">联系电话</div>
      <div class="position-sub">
        <div class="sub-box"> 
          <div class="sub-txt">
            <span class="name">王老师</span><span class="phone">13161510966</span>
          </div>
          <div class="sub-img">
            <img class="imgs" src="../assets/site/home/wang.jpg" />
          </div>
        </div>
      </div>
    </div>
    <div class="aside-item try" @click="goToNeed">
      <div class="aside-imgs"></div>
      <div class="aside-txt">免费试用</div>
    </div>
    <div class="aside-item down" @click="backtop">
      <div class="aside-imgs"></div>
      <div class="aside-txt">返回顶部</div>
    </div>
  </div>
</template>

<script>
import utils from '../config/utils';
export default {
  name: "Aside",
  components: {},
  data () {
    return {}
  },
  methods: {
    backtop () {
      var timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30)
    },
    goToNeed () {
      utils.redirect(this, "/need", true);
    }
  }
}
</script>

<style scoped>
.fixed-aside-box {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 100;
    width: 70px;
    transform: translate(0, -50%);
    background-color: #fff;
    box-shadow: 0px 0px 7px 1px rgba(61, 119, 255, 0.12);
    border-radius: 8px 0 0 8px;
}
.fixed-aside-box .top-imgs {
    width: 100%;
    height: 68px;
    background: url('../assets/site/fixed/doctor.png') no-repeat center;
    background-size: 100% auto;
}
.aside-item {
    background-color: #fff;
    width: 100%;
    margin: 15px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
}
.aside-item .aside-imgs {
    width: 28px;
    height: 28px;
    background-position: center;
    margin: 0 auto 8px;
}
.aside-item:hover .aside-txt {
    color: #3d77ff;
}
.aside-item.wechat .aside-imgs {
    background: url('../assets/site/fixed/wechat.png') no-repeat center;
    background-size: 100% auto;
}
.aside-item.wechat:hover .aside-imgs {
    background: url('../assets/site/fixed/wechat-active.png') no-repeat center;
    background-size: 100% auto;
}
.aside-item.phone .aside-imgs {
    height: 24px;
    background: url('../assets/site/fixed/phone.png') no-repeat center;
    background-size: 100% auto;
}
.aside-item.phone:hover .aside-imgs {
    height: 24px;
    background: url('../assets/site/fixed/phone-active.png') no-repeat center;
    background-size: 100% auto;
}
.aside-item.try .aside-imgs {
    background: url('../assets/site/fixed/try.png') no-repeat center;
    background-size: 100% auto;
}
.aside-item.try:hover .aside-imgs {
    background: url('../assets/site/fixed/try-active.png') no-repeat center;
    background-size: 100% auto;
}
.aside-item.down .aside-imgs {
    height: 15px;
    background: url('../assets/site/fixed/down.png') no-repeat center;
    background-size: 100% auto;
    width: 22px;
}
.aside-item.down:hover .aside-imgs {
    background: url('../assets/site/fixed/down-active.png') no-repeat center;
    background-size: 100% auto;
}
.aside-txt {
    font-size: 12px;
    color: #939292;
}
.position-sub {
    position: absolute;
    left: 0;
    top: -10px;
    padding: 15px;
    background-color: #fff;
    display: none;
    box-shadow: 0px 0px 7px 1px rgba(61, 119, 255, 0.12);
    border-radius: 8px 0 0 8px;
}
.position-sub::after {
    width: 8px;
    height: 100%;
    content: '';
    display: inline-block;
    position: absolute;
    right: -4px;
    top: 0;
    z-index: 20;
    background-color: #fff;
}
.aside-item.wechat:hover .position-sub {
    display: block;
    left: -132px;
    top: -16px;
}
.position-sub .sub-box {
  width: 100%;
  display: flex;
}
.position-sub .sub-box .sub-txt {
  margin-right: 15px;
  text-align: left;
}
.position-sub .sub-box .sub-txt .name {
  font-weight: bold;
  font-size: 16px;
  color: #212224;
  display: block;
  margin: 15px 0;
}
.position-sub .sub-box .sub-txt .phone {
  font-weight: 400;
  font-size: 14px;
  color: #2B2C2E;
  border-bottom: 6px solid #EFF6FF;
}
.position-sub .sub-box .sub-img  img{
  width: 90px;
  height: auto;
}
.aside-item.wechat .position-sub .position-img {
    width: 99px;
    height: 101px;
    background: url('../assets/site/home/qrcode.jpg') no-repeat center;
    background-size: 100% auto;
}
.aside-item.phone:hover .position-sub {
    display: block;
    left: -236px;
    top: -11px;
}
.aside-item.phone .position-sub .position-txt {
    font-size: 16px;
    font-weight: bold;
    color: #939292;
    line-height: 30px;
}
</style>