<template>
  <div class="footer-box">
    <div class="c footer-relevant-box">
      <img class="left-imgs-box" src="../assets/site/logo-footer-white.png" />
      <!-- <div class="site-category-box">
        <div class="category-items">
          <span class="link-name">融媒智库： </span>
          <router-link class="link-opt" :to="{path: '/media', query: {type: 'mtkx'}}">媒体快讯</router-link>
          <router-link class="link-opt" :to="{path: '/media', query: {type: 'zjdt'}}">职教动态</router-link>
          <router-link class="link-opt" :to="{path: '/media', query: {type: 'rmzsk'}}">融媒知识库</router-link>
        </div>
        <div class="category-items">
          <span class="link-name">课程资源： </span>
          <router-link class="link-opt" :to="'/school/class'">全部课程</router-link>
        </div>

        <div class="category-items">
          <span class="link-name">院校服务： </span>
          <router-link class="link-opt" :to="'/service/teaching'">教学资源</router-link>
          <router-link class="link-opt" :to="'/service/training'">实训平台</router-link>
          <router-link class="link-opt" :to="'/service/study'">在线学习平台</router-link>
        </div>
        <div class="category-items">
          <span class="link-name">党建思政： </span>
          <div class="link-opt" @click="goToThought(1)">党建服务</div>
          <div class="link-opt" @click="goToThought(2)">思政培训</div>
        </div>
      </div> -->
      <div class="site-address">
        <div>北京中教互联教育科技有限公司</div>
        <div>北京市海淀区文慧园北路9号今典花园9号楼-中国教育在线</div>
      </div>
      <div class="site-contact-box">
        <div class="category-items">
          <div class="items-left">
            <div class="label">业务合作</div>
            <div class="phone">王老师<br/>13161510966</div>
          </div>
          <div class="items-right">
            <img class="wechat-imgs" src="../assets/site/home/wang.jpg" />
          </div>
        </div>
        <div class="category-items">
          <div class="items-left">
            <div class="label">公众号</div>
            <div class="phone">中教云课</div>
          </div>
          <div class="items-right">
            <img class="wechat-imgs" src="../assets/site/home/qrcode.jpg" />
          </div>
        </div>
        
      </div>
    </div>
    <p class="bottom-copyright">
      <span>版权所有</span>
      <!-- <span><a href="https://www.eol.cn" target="_blank">中国教育在线</a></span> -->
      <span>北京中教互联教育科技有限公司</span>
      <a href="http://www.eol.cn/html/cer.net/icp.htm" target="_blank">京ICP证140769号</a> |
      <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">京ICP备2022007846号-1 </a> |
      <a href="http://img.eol.cn/images/indexnew/www1024.jpg" target="_blank">京网文[2014]2106-306号</a> |
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236" target="_blank">京公网安备 11010802020236号</a>
    </p>

  </div>
</template>

<script>
import utils from '../config/utils';
export default {
  name: 'Footer',
  methods: {
    goToMedia (type) {
      this.$router.push({
        path: '/media',
        query: { type }
      })

    },
    goToThought (type) {
      utils.redirect(this, `/thought?type=${type}`);
    }
  }
}
</script>

<style lang="less" scoped>
.footer-box {
    background-color: #161e30;
    color: #d6d6d6;
    font-size: 14px;
}
.footer-box .footer-relevant-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 45px 0 40px;
}
.footer-box .left-imgs-box {
    width: 189px;
    height: auto;
    margin-right: 70px;
}
.footer-box .right-wechat-box {
    width: 107px;
    text-align: center;
}
.footer-box .site-category-box {
    flex: 1;
    padding-left: 90px;
}
.footer-box .site-contact-box {
    flex: 1;
    padding-left: 60px;
    display: flex;
  .category-items {
    display: inline-block; 
    display: flex;
    margin-right: 60px;
    .items-left {
      display: inline-block;
      margin-right: 20px;
      .label {
        font-weight: bold;
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 20px;
      }
      .phone {
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
    }
    .items-right {
      img {
        width: 90px;
        height: auto;
      }
    }
  }
}
.footer-box .link-opt {
    margin-right: 20px;
    cursor: pointer;
    display: inline-block;
    color: #d6d6d6;
}
.link-name {
    color: #fff;
}
.footer-box .link-opt:hover {
    color: #3d77ff;
    text-decoration: none;
}
.footer-box .wechat-imgs {
    width: 100%;
    height: auto;
}
.footer-box .bottom-copyright {
    background-color: #0d1422;
    height: 48px;
    line-height: 48px;
    text-align: center;
}
.bottom-copyright span,
.bottom-copyright a {
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    color: #d1d0d0;
    font-size: 12px;
}
.bottom-copyright a:hover {
    color: #3d77ff;
    text-decoration: none;
}
.link-name.big {
    font-size: 16px;
    font-weight: bold;
}
</style>