<template>
  <div class="main-footer">
    <div class="glbbot">
      <a href="http://www.eol.cn/html/cer.net/icp.htm">京ICP证140769号</a> |
      <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">
        京ICP备2022007846号-1 </a>|
      <a href="http://img.eol.cn/images/indexnew/www1024.jpg">京网文[2014]2106-306号</a> |
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802020236">京公网安备 11010802020236号</a>
      <br />
      版权所有 北京中教互联教育科技有限公司<br />
      Mail to: <a href="mailto:wangjr@eol.cn">wangjr@eol.cn</a>
    </div>
  </div>
</template>

<script>
// import "../assets/css/yxtLogin.css"
export default {
  name: "loginFooter",
  components: {},
  data () {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped>
/*footer*/
.main-footer {
    padding: 15px 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: #787878;
    font-size: 12px;
}

.main-footer .glbbot {
    line-height: 20px;
    text-align: center;
}

.main-footer a {
    vertical-align: top;
    text-decoration: none;
    color: #787878;
}

.main-footer .glbbot a:link,
.main-footer .glbbot a:visited {
    color: #787878;
    text-decoration: none;
}

.main-footer .glbbot a:hover,
.main-footer .glbbot a:active {
    color: #787878;
    text-decoration: underline;
}
.mainbody.static-page-box .main-footer {
    position: relative;
}
.user-agree-page .main-footer {
    background-color: #f1f2f7;
}
</style>